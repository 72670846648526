// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"


import {gsap, TweenMax, TimelineMax, ScrollTrigger, Flip, ScrollToPlugin, TextPlugin} from "./gsap/esm/all";
gsap.registerPlugin(ScrollTrigger);
require("jquery")

// import jQuery from 'jquery';
global.$ = window.$ = window.jQuery = jQuery;


// import '@popperjs/core'; // Edit here
import "bootstrap"

import 'owl.carousel/dist/assets/owl.carousel.css';
import "./owl-carousel"
// import 'owl.carousel';


import Dropzone from "dropzone";

import Shuffle from 'shufflejs';
import "../styles/application.scss"

import "jquery.dotdotdot"


require("jquery-ui")


const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

window.gsap = gsap;
window.Dropzone = Dropzone;


Rails.start()
ActiveStorage.start()

var initialResultsShown = false;
var boo = true

var resizeTimeout;
var hasResults = false;
var currentShuffle = null;
var currentOwl = null;
var carouselSettings = {
    margin:10,
    merge: true,

    responsive:{
        0:{
            items:1,
            stagePadding: 40,
            fallbackEasing: 280,
            smartSpeed: 280,
            fluidSpeed: 280,
            onRefreshed: function(){
                runDotdotdot();
            }
        },
        321:{
            items:2,
            stagePadding: 0,
            fallbackEasing: 280,
            smartSpeed: 280,
            fluidSpeed: 280,
            onRefreshed: function(){
                runDotdotdot();
            }
        },
        576:{
            items:3,
            stagePadding: 0,
            onRefreshed: function(){
                runDotdotdot();
            }
        },
        768:{
            items:4,
            stagePadding: 0,
            onRefreshed: function(){
                runDotdotdot();
            }
        },
        1024: {
            items:5,
            stagePadding: 0,
            onRefreshed: function(){
                runDotdotdot();
            }
        }
    }
}

// Shuffle.options.isCentered = true;
// Shuffle.options.gutterWidth = 25;

Shuffle.options.speed = 750;
Shuffle.options.staggerAmount = 100;

function search( value ) {
    trackingSearchStart();
    trackSpecificSearchTerm(value);

    $(".pagination").css("opacity", 0);

    $.ajax({
        method: "GET",
        url: "/search?query=" + encodeURIComponent(value),
        dataType: "text",
        success: function (data) {
            eval( data )
            trackingSearchResultComplete();
        }
    });
}

window.reloadToTop = function() {
    $('body').hide();
    window.scrollTo(0,0);
    window.location.reload(true);
}

function autocompleteWidthAdjustmentPretty() {
    var width = $(".pretty-search-form .ui-autocomplete-input").outerWidth();
    $('#ui-id-2.ui-menu.ui-autocomplete').css('max-width',width);
}
function autocompleteWidthAdjustmentSimple() {
    var width = $(".simple-search-form .ui-autocomplete-input").outerWidth();
    $('#ui-id-1.ui-menu.ui-autocomplete').css('max-width',width);
}

$(function(){

    // ### Email Signup Validation ###

    $('#email-sign-up').submit(function(){
        console.log("submit")
        trimById("email")

        var valid = validateAll();
        if(valid) {
            $("#submitEmailBtn").prop( "disabled", true );
            $(".emailConfirmMessage").removeClass( "hide-confirm-message" )
            $("#email-sign-up").addClass( "hide-form-confirmed" );
        }

        return valid;
        // return valid; ### to be used when form action is built
    });

    function validateAll() {
        var valid = true;

        if(!validateEmail( $("#email").val() ) ) {
            console.log("Invalid Email");
            $(".emailErrorMessage").removeClass("hide-error-message");
            valid = false;
        } else {
            $(".emailErrorMessage").addClass("hide-error-message");
        }

        return valid;
    }

    function trimById(id) {
        $("#" + id).val($("#" + id).val().trim());
    }

    function validateEmail(email) {
        var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
        return re.test(email);
    }

    // ### Derma Carousel ###
    $('#DermaReviewSection .dermaReview.owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        nav:false,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                stagePadding: 80,
            },
            350:{
                items:1,
                stagePadding: 100,
            },
            400:{
                items:1,
                stagePadding: 110,
            },
            450:{
                items:1,
                stagePadding: 130,
            },
            500:{
                items:2,
                stagePadding: 92,
            },
            600:{
                items:2,
                stagePadding: 110,
            },
            700:{
                items:2,
                stagePadding: 130,
            },
            768:{
                items:3,
                loop:false,
            },
            1000:{
                items:3,
                loop:false,
            }
        }
    })

    // ### Article Card Ellipsis ###
    $(".articleCard .articleWrap .textWrap").dotdotdot();

    $(".result-card").hide();

    $(".filter-container").hide();

    $(window).resize( function() {
        clearTimeout(resizeTimeout);
        if(initialResultsShown)
            resizeTimeout = setTimeout(initializeResultCards, 750);


        runDotdotdot();
    });

    $(".pretty-search-form").submit(function(){
        var val = $(".pretty-search-form input").val();
        $(".simple-search-form input").val( val );


        if( val.length == 0 ) {
            showSearchError();
            return false
        }

        $("input").blur();



        if(!hasResults) {
            search(val);
        } else {
            removeResultsAndSearch(val);
        }

        // showInitialResults();


        return false;
    });
    $(".pretty-search-form a").click(function(){
        $(".pretty-search-form").submit();
    });





    $(".simple-search-form").submit(function(){
        var val = $(".simple-search-form input").val();
        $(".pretty-search-form input").val( val );

        if( val.length == 0 ) {
            showSearchError();
            return false
        }

        $("input").blur();


        if(!hasResults) {
            search(val);
        } else {
            removeResultsAndSearch(val);
        }

        return false;
    });
    $(".simple-search-form a").click(function(){
        $(".simple-search-form").submit();
    });



    $(".simple-search-clear").click(function(){
        $(".simple-search-form input").val("");

        scrollToSearchInstant();
    });



    $(".pretty-search-clear").click(function(){
        $(".pretty-search-form input").val("");

        scrollToSearchInstant();
    });


    $("window").on("click", "card-click-through", function(e){
       $(e.target) .find("a").click();
    });

    //
    // $(".disclaimer").click(function(){
    //     $(this).toggleClass("open")
    // })

    $('[data-toggle="tooltip"]').tooltip();

    $(".results-container").hide();
    // $(".simple-search-form").hide();

    gsap.registerPlugin(Flip, ScrollToPlugin);
    // $(".hero-content").click(function(){
    //     showInitialResults();
    // });

    //
    // showInitialResults();







    //TEXT ANIMATE PLACEHOLDER
    var speed = 50;

    /* -------------------------------------------------*/
    var placeholderTextParts = $('#pretty_search').attr('placeholder').split('');
    $('#pretty_search').attr('placeholder',' ')
    curPlacedholderAnimIndex = 0;
    function addToPlaceholder(){
        var curVal = $("#pretty_search").attr("placeholder");
        $("#pretty_search").attr("placeholder", curVal + placeholderTextParts[curPlacedholderAnimIndex]  );
        curPlacedholderAnimIndex++;
        if( curPlacedholderAnimIndex < placeholderTextParts.length ){
            setTimeout( addToPlaceholder , speed );
        }
    }
    setTimeout( addToPlaceholder , 1500 );






    //
    // setTimeout(function() {
    //
    //     var state = Flip.getState("nav, nav h1, nav a, .find-derm-inner")
    //     $("nav").addClass("minimized");
    //
    //     Flip.from(state, {
    //         absolute: true,
    //         duration: 0.75,
    //         delay: 0,
    //         nested: true,
    //         ease: "power2.out",
    //         onComplete: function(){
    //         }
    //     })
    //
    // }, 1000)



    //Animate the "find physician" button in the nav based on scroll position
    $(window).scroll(function (event) {
        var scroll = $(this).scrollTop();
        var addMinimizeClass = false;
        var scrollIsTopChange = false;

        var scrollIsAtTop = scroll <= 0;
        var hasMinimizedClass = $("nav").hasClass("minimized")

        if( ( scrollIsAtTop && hasMinimizedClass ) ){
            scrollIsTopChange = true;
            addMinimizeClass = false;
        } else if( !scrollIsAtTop && !hasMinimizedClass ) {
            scrollIsTopChange = true;
            addMinimizeClass = true;
        }

        if( scrollIsTopChange ) {
            var state = Flip.getState("nav h1, nav a, .find-derm-inner")

            if ( addMinimizeClass)
                $("nav").addClass("minimized");
            else
                $("nav").removeClass("minimized");

            Flip.from(state, {
                absolute: true,
                duration: 0.5,
                delay: 0,
                nested: true,
                ease: "power2.out",
                onComplete: function(){
                }
            })
        }
    });



    $(".image-wrapper").click(function(){
        var dataIndex = $(this).attr("data-index");
        rotateTalent(dataIndex)

        clearInterval( autoPlayInterval );
    })

    autoPlayInterval = setInterval(function(){
        var next = $('.image-wrapper[data-position="1"]').attr("data-index");
        rotateTalent(next);
    }, 6000 )




    $('#imageModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget) // Button that triggered the modal

        var dataDiv = $(button).find(".modal-data");
        var img = $(button).find(".thumb-img");
        var header = $(dataDiv).find("h3");
        var p = $(dataDiv).find("p");

        var modal = $(this)
        modal.find('.modal-header img').attr("src", img.attr("src"));
        modal.find('.modal-body h3').replaceWith(header.clone());
        modal.find('.modal-body p:first').replaceWith(p.clone());
    })


    $('#definitionModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget) // Button that triggered the modal

        var dataDiv = $(button).find(".modal-data");
        var header = $(dataDiv).find("h3");
        var p = $(dataDiv).find("p");

        var modal = $(this)
        modal.find('.modal-body h3').replaceWith(header.clone());
        modal.find('.modal-body p:first').replaceWith(p.clone());
    })

    $('#assistedSearchModal').on('show.bs.modal', function (event) {
        var modal = $(this)
        modal.find('.selected').removeClass("selected");

        $("#assistedSearchModal .error").removeClass("show");
        $("#assistedSearchModal .assisted-search-submit-btn").addClass("btn-disabled");
    })




    $(".pagination-btn.previous").click(prevPage);
    $(".pagination-btn.next").click(nextPage);
})

var autoPlayInterval = null;


function runDotdotdot() {
    $(".definition-card .result-card-body").dotdotdot();

    var containerWidth = $('body').find('header').outerWidth();
    if( containerWidth >= 321  ) {
        $(".image-card .result-card-body p, .article-card .result-card-body p").dotdotdot({height: 50});
    } else if( containerWidth >= 576 ) {
        $(".image-card .result-card-body p, .article-card .result-card-body p").dotdotdot({height: 50});
    } else if( containerWidth >= 768 ) {
        $(".image-card .result-card-body p, .article-card .result-card-body p").dotdotdot({height: 50});
    } else if( containerWidth >= 1024 ) {
        $(".image-card .result-card-body p, .article-card .result-card-body p").dotdotdot({height: 55});
    } else {
        $(".image-card .result-card-body p, .article-card .result-card-body p").dotdotdot({height: 35});
    }
}
function rotateTalent(dataIndex) {
    var state = Flip.getState(" .image-wrapper, .image-wrapper img, .talent .backdrop", {props: "opacity"});

    var talentImagesContainerHeight = $(".talent-images").height();
    $(".talent-images").height( talentImagesContainerHeight );

    if(dataIndex == 0) {
        $('.image-wrapper[data-index="0"]').attr("data-position", "0")
        $('.image-wrapper[data-index="1"]').attr("data-position", "1")
        $('.image-wrapper[data-index="2"]').attr("data-position", "2")

    } else if (dataIndex == 1) {
        $('.image-wrapper[data-index="0"]').attr("data-position", "2")
        $('.image-wrapper[data-index="1"]').attr("data-position", "0")
        $('.image-wrapper[data-index="2"]').attr("data-position", "1")
    } else {
        $('.image-wrapper[data-index="0"]').attr("data-position", "1")
        $('.image-wrapper[data-index="1"]').attr("data-position", "2")
        $('.image-wrapper[data-index="2"]').attr("data-position", "0")
    }



    gsap.killTweensOf(".caption")

    gsap.to(".caption.active",
        {
            autoAlpha: 0,
            duration: 0.5,
            onComplete: function(){

                $('.caption.active').removeClass("active");
                $('.caption[data-index="' + dataIndex + '"]').addClass("active");

                // gsap.killTweensOf(".caption.active")
                gsap.fromTo(".caption.active",
                    {
                        opacity: 0,
                    },
                    {
                        autoAlpha: 1,
                        duration: 1
                    }
                );
            },
        });


    Flip.from(state, {
        absolute: true,
        duration: 0.75,
        delay: 0,
        nested: true,
        ease: "power1.inOut",
        onComplete: function(){
            $(".talent-images").height("auto");
        }
    })
}


var curPlacedholderAnimIndex;




/*
* Depending on device size, initialize result cards as carousel or a shuffle js wall
* */
function initializeResultCards(){

    initialResultsShown = true;

    $(".result-card").show();
    if(!hasResults)
        return
    var containerWidth = $('body').find('header').outerWidth();
    if(containerWidth <= 767) {
        var waitForShuffle = false;
        if( currentShuffle != null ) {
            currentShuffle.destroy();
            currentShuffle = null;
            $(".result-card-list").removeClass("shuffled");
            gsap.killTweensOf("*");
            waitForShuffle = true;
        }

        // initialize owl-carousel if window screensize is less the 767px
        if (currentOwl == null) {
            $(".result-card-inner").css("visibility", "hidden")

            if( !waitForShuffle ) {
                startOwl();
            } else {

                $(".results-container").addClass("loading");
                setTimeout(startOwl, 4000)
            }
        }
    } else {
        // destroy owl-carousel and remove all depending classes if window screensize is bigger then 767px
        var waitForCarousel = false;
        if(currentOwl != null) {
            currentOwl.removeClass("owl-carousel")
            currentOwl.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
            currentOwl.find('.owl-stage-outer').children().unwrap();
            currentOwl = null;
            waitForCarousel = true;
        }
        if( currentShuffle == null ) {
            if(!waitForCarousel) {
                initShuffleJs();
            } else {
                setTimeout(initShuffleJs, 500)
            }
        }

    }
}

function startOwl() {
    console.log("owl")
    $(".result-card-inner").css("visibility", "visible")


    currentOwl = $(".result-card-list");
    currentOwl.addClass("owl-carousel")
    currentOwl.owlCarousel(carouselSettings);

    // disable scroll
    currentOwl.on('drag.owl.carousel', function (event) {
        document.ontouchmove = function (e) {
            e.preventDefault()
        }
    })

    // enable scroll
    currentOwl.on('dragged.owl.carousel', function (event) {
        document.ontouchmove = function (e) {
            return true
        }
    })

    showNewResultsCardAnim();

    $(".results-container").removeClass("loading");
}


window.currentPage = 0;
window.numPages = 0;
window.countPerPage = []
window.totalResults = 0;
window.totalResultsPluralized = "0 Results";
var numPerPage = 16;
var numSpaceholdersFirstPage = 3;

window.nextPage = function() {
    window.currentPage++;

    if( currentPage > numPages - 1 ) currentPage = numPages - 1;
    updateResultCounts();

    filterShufflePage();


    scrollToSearchAnim();

    updatePaginator();
    return false;
}

window.prevPage = function() {
    window.currentPage--;

    if( currentPage < 0 ) currentPage = 0;

    updateResultCounts();
    filterShufflePage();


    scrollToSearchAnim();

    updatePaginator();
    return false;
}

function updateResultCounts() {
    var containerWidth = $('body').find('header').outerWidth();

    var startIndex = currentPage * numPerPage - numSpaceholdersFirstPage;
    if( startIndex < 0 ) {
        startIndex = 0;
    }
    var endIndex = startIndex + numPerPage - 1;
    if(currentPage == 0) {
        endIndex -= numSpaceholdersFirstPage;
    }

    if( endIndex > totalResults ) {
        endIndex = totalResults - 1;
    }

    $(".result-count .from").text( startIndex + 1 );
    $(".result-count .to").text( endIndex + 1 );
    $(".result-count .total").text( totalResultsPluralized );

    updatePaginator();
}
window.updateResultCounts = updateResultCounts;

function updatePaginator() {
    if( numPages > 1 ) {
        $(".pagination-btn.previous, .pagination-btn.next").show();

        if(currentPage == 0) {
            $(".pagination-btn.previous").hide();
        }

        if( currentPage == numPages - 1 ) {
            $(".pagination-btn.next").hide();
        }

    } else {
        $(".pagination-btn.previous, .pagination-btn.next").hide();
    }
    $(".pagination").css("opacity", 1);
}

function filterShufflePage() {
    currentShuffle.filter((element) => {
        return parseInt(element.dataset.page) == currentPage
    });
}

function initShuffleJs() {
    $(".result-card-list").addClass("shuffled");
    $(".result-card").show();
    currentShuffle = new Shuffle(document.querySelector('.result-card-list'), {
        itemSelector: '.result-card',
//   sizer: '.my-sizer-element',
        buffer: 1,
    });
    filterShufflePage();
    updateResultCounts();
    window.currentShuffle = currentShuffle;

    showNewResultsCardAnim();


    runDotdotdot();
}

function showNewResultsCardAnim() {


    var isShuffle = currentShuffle != null;
    var flipDuration = isShuffle ? 2 : 0.75;
    var staggerSpeed = 1 / $(".result-card").length;
    var fadeDelay = isShuffle ? 0.5 : 0.2;


    $(".result-card-list").hide();
    $(".result-card-list").css("opacity", 0);

    var state = Flip.getState(".results-container")
    $(".result-card-list").show();
    $(".result-card-list").css("opacity", 1);


    $(".filter-container").show();
    Flip.from(state, {
        duration: flipDuration,
        delay: fadeDelay,
        nested: true,
        onComplete: function(){
            $(".disclaimer").addClass("ready");
        }
    })

    gsap.fromTo(".filter-container",
        {
            opacity: 0,
            y: "-20"
        },
        {
            opacity: 1,
            y: "0",
            duration: 0.4,
            delay: fadeDelay,
            ease: "power2.out"
        });
    gsap.fromTo(".result-card-inner", {opacity: 0, y: 20}, {
        duration: .7,
        delay: fadeDelay + .2,
        opacity:1,
        y: 0,
        ease: "power1.out",
        clearProps: 'all',
        stagger: {
            each: staggerSpeed
        },
    });

}

var resultDeinitTime;

function removeResultsAndSearch( value ) {
    gsap.to(".result-card-inner",
        {
            opacity: 0,
            y: 20,
            duration: .7,
            ease: "power1.out", onComplete: function(){


                var state = Flip.getState("header, .results-container, .hero-content, .hero-content h2, .simple-search-form, .pretty-search-form, .additional-content, section.story, .video-positioner")
                $(".results-container").addClass("loading");
                gsap.to($(".no-result-default"), 0.25, {opacity: 0});

                Flip.from(state, {
                    duration: .75,
                    nested: true,
                    absolute: ".pretty-search-form, .results-container, .simple-search-form, section.story",
                    onComplete: function(){
                        if( currentShuffle != null ) {
                            currentShuffle.destroy();
                            currentShuffle = null;
                            $(".result-card-list").removeClass("shuffled");
                            gsap.killTweensOf("*");
                        }

                        if(currentOwl != null) {
                            currentOwl.removeClass("owl-carousel")
                            currentOwl.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
                            currentOwl.find('.owl-stage-outer').children().unwrap();
                            currentOwl = null;
                        }

                        resultDeinitTime = Date.now();
                    }
                } );
                search(value );
                
            }});


    scrollToSearchAnim();
}

window.removeResultsAndSearch = removeResultsAndSearch;
window.Flip = Flip;


function handleSearchSuccess() {
    if(!hasResults) {
        showInitialResults()
    } else {
        handleSubsequentSearchSuccess();
    }
    initAnimation();
}

window.scrollToSearchAnim = function() {
    gsap.to(window, {duration: 0.75, scrollTo: getSimpleSearchScrollPos() });
}
window.scrollToSearchInstant = function() {
    $(window).scrollTop( getSimpleSearchScrollPos() );
}

function handleSubsequentSearchSuccess(){
    var containerWidth = $('body').find('header').outerWidth();

    var timeForResults = Date.now() - resultDeinitTime;
    console.log("Time for results: ", timeForResults)
    var timeout = 500;
    if(containerWidth <= 767) {
        timeout -= timeForResults;
        if(timeout < 0) timeout = 0;
        setTimeout(startOwl, timeout)
    } else {
        timeout = 2000;
        timeout -= timeForResults;
        if(timeout < 0) timeout = 0;
        setTimeout(initShuffleJs, timeout)
    }

    setTimeout(function(){
        var state = Flip.getState("header, .results-container, .hero-content, .hero-content h2, .simple-search-form, .pretty-search-form, .additional-content, section.story, .video-positioner")
        $(".results-container").removeClass("loading");
        Flip.from(state, {
            duration: .75,
            nested: true,
            absolute: ".pretty-search-form, .results-container, .simple-search-form, section.story"
        } );
        
    }, timeout);
}
window.handleSearchSuccess = handleSearchSuccess;
window.handleSubsequentSearchSuccess = handleSubsequentSearchSuccess;


function getSimpleSearchScrollPos() {

    // var paddingTopHeader = parseInt( $('body').find('header').css("padding-top") );
    //
    // var containerWidth = $('body').find('header').outerWidth();
    // return parseInt($(".lockup-search-container").offset().top) - paddingTopHeader;

    if($('.pretty-search-form').is(':visible')){
        // alert("mobile")
        return parseInt($(".lockup-search-container").offset().top - $("nav").height() - $(".pretty-search-form .top-lockup-sizer").height() - 5 );
    } else
    {
        // alert("desktop")
        return parseInt($(".lockup-search-container").offset().top - $("nav").height() - 30);
    }



}

function showInitialResults() {

    $(".results-container").addClass("loading");

    var state = Flip.getState("header, .results-container, .hero-content, .hero-content h2, .simple-search-form, .pretty-search-form, .additional-content, section.story, .video-positioner")
    if( boo ){
        // var h = $(".hero-content").height();
        // $(".intro").css("height", h)

        var heroVideo = $(".collarbone-holder");
        heroVideo.height( heroVideo.height() );
        heroVideo.width( heroVideo.width() );
        $(".collarbone-holder").addClass("searched");

        $(".results-container").show();
        $(".hero-content").addClass("searched");
        $(".pretty-search-form").addClass("searched");
        $(".simple-search-form").addClass("searched");
        $("section.story").addClass("searched");




        // console.log($(".intro").height());
    }
    boo = false

    var scrollPos = getSimpleSearchScrollPos();


    Flip.from(state, {
        duration: .75,
        nested: true,
        absolute: ".pretty-search-form, .results-container, .simple-search-form, section.story",
        onEnter: function() {

            gsap.fromTo(".results-container",
                {
                    opacity: 0
                },
                {
                    opacity: 1,
                    // delay: 0.45,
                    duration: 0.5
                });

            gsap.fromTo(".pretty-search-inner",
                {
                    opacity: 1,
                    y: 0
                },
                {
                    opacity: 0,
                    y: "-70",
                    duration: 0.35,
                    scale: 0.95,
                    ease: "power1.in"
                });


            gsap.fromTo(".simple-search-inner",
                {
                    opacity: 0,
                    y: "120"
                },
                {
                    opacity: 1,
                    y: "0",
                    duration: 0.3,
                    delay: 0.25,
                    ease: "power1.out"
                });

            // scrollToSearchAnim()

            gsap.to(window, {duration: 0.75, scrollTo: scrollPos });

            // $(".pagination").css("opacity", 1);
        },
    })

    setTimeout(function(){
        // $(".result-card").show();
        hasResults = true;
        $(".results-container").removeClass("loading");
        initializeResultCards();
        }, 2000);

}
window.showInitialResults = showInitialResults;

function showSearchError() {
    $(".simple-search-inner").addClass("error")
    setTimeout(function(){ $(".simple-search-inner").removeClass("error") }, 3000);

    shakeAnimation(".top-lockup-sizer")
}

function shakeAnimation(element){
    TweenMax.to(element, .1, {
        x: 0,
        ease: "power4.inOut"
    });
    TweenMax.to(element, .1, {
        repeat: 4,
        x: 3,
        yoyo: true,
        delay: .1,
        ease: "power4.inOut"
    });
    TweenMax.to(element, .1, {
        x: 0,
        delay: .1 * 4 + .1
    });
}

window.showSearchError = showSearchError






Dropzone.options.donateForm = { // camelized version of the `id`
    maxFilesize: 5, // MB

    acceptedFiles: ".png,.jpg,.gif,.bmp,.jpeg",
    dictDefaultMessage: " ",

    thumbnailWidth: null,
    thumbnailHeight: null,
    addRemoveLinks: true,
    paramName: "donation[photo]",
    maxFiles: 1,
    init: function() {
        $(".dropzone .dz-message").prepend("<div class='inner'><span>+ Upload an Image</span></div>");

        this.on("thumbnail", function (file, dataUrl) {
            $('.dz-image').last().find('img').attr({width: '100%', height: '100%'});
            $('.dz-image').css({"width": "100%", "height": "auto"});
            // $('.dz-error-mark').text("Replace Image");
        }),
        this.on("success", function (file,responseText) {
            $('.dz-image').css({"width": "100%", "height": "auto"});

            eval(responseText)

        }),
        this.on("error", function (file) {
            $(".dropzone .dz-preview .dz-error-mark").last().append("<span>view error</span>")
            $("#donateModal .submit-btn").addClass("disabled")
        }),

        this.on('addedfile', function (file) {
            if (this.files.length > 1) {
                this.removeFile(this.files[0]);
                $("#donateModal .submit-btn").addClass("disabled")
            }
        }),
        this.on('removedfile', function (file) {
            $("#donateModal .submit-btn").addClass("disabled")
        })
    }
};


$(function(){

    $( "#simple_search" ).autocomplete({

        position: {
            my: "left+0 top+5",
        },

        source: function( request, response ) {
            $.ajax( {
                url: "/autosuggest",
                dataType: "json",
                data: {
                    search: request.term
                },
                success: function( data ) {
                    console.log(data)
                    response( data );
                },
                fail:  function( data ) {
                    console.log(data)
                },
            } );
        },
        select: function( event, ui ) {
            var value = ui.item.value;
            $(".pretty-search-form input").val( value );
            $(".simple-search-form input").val( value );

            $(".simple-search-form").submit();
        }
    });
    $( "#pretty_search" ).autocomplete({

        position: {
            my: "left+0 top+18",
        },

        source: function( request, response ) {
            $.ajax( {
                url: "/autosuggest",
                dataType: "json",
                data: {
                    search: request.term
                },
                success: function( data ) {
                    console.log(data)
                    response( data );
                },
                fail:  function( data ) {
                    console.log(data)
                },
            } );
        },
        minLength: 2,
        select: function( event, ui ) {
            var value = ui.item.value;
            $(".pretty-search-form input").val( value );
            $(".simple-search-form input").val( value );

            $(".pretty-search-form").submit();
        }
    });

    // ### Search Max Width ###
    autocompleteWidthAdjustmentPretty();

    $( window ).resize(function() {
        autocompleteWidthAdjustmentPretty();
        autocompleteWidthAdjustmentSimple();
    });

    $(document).on('click', '#donateModal .tag-group li', function(){
        $(this).toggleClass("selected");
    });

    $(document).on('click','.donate-add-details-submit-btn', function(){
        alert('Clicked');
    });

    $(document).on('click', '#donateModal .submit-btn', function(){
        var tags = [];
        $(".tag-group.descriptors .selected").each(function () {
            tags.push( $(this).text() );
        });

        $(".tag-group.what .selected").each(function () {
            tags.push( $(this).text() );
        });

        $(".tag-group.where .selected").each(function () {
            tags.push( $(this).text() );
        });

        var tagsString = tags.join(", ");
        $("#donation_tags").val(tagsString)

        // alert(tagsString)
    });


    $(".get-appointment-guide-btn").click(window.trackingGetGuide)


});
$(window).on('load', function() {
    animateLinks();
    initAnimation();
    gsap.to('.collarbone-holder', {
        opacity: 1,
        duration: 1,
        ease: 'power3.out',
    })
    gsap.to('.collarbone-holder', {
        y: 0,
        scale: 1,
        duration: 7.5,
        ease: 'power1.out',
        onComplete: function() {
            // TweenMax.set(".collarbone-holder", {clearProps: 'all'});
        }
    })
});


function initAnimation() {

    let timeline = gsap.timeline();
    timeline.to('nav', {
        duration: 0.7,
        opacity: 1,
        ease: 'ease-out',
        y: 0,
    })
    .to('.hero-content', {
        duration: 0.6,
        delay: -0.5,
        opacity: 1,
        ease: 'power3.out',
    })
    .to('.pretty-search-submit', {
        left: 0,
        duration: 1.5,
        opacity: 1,
        ease: 'power3.out',
    })
    .to('#pretty_search', {
        duration: 0.5,
        opacity: .8,
        delay: -1,
        ease: 'power3.out',
    })
    .add(function() {
        $('.top-lockup-sizer').addClass('animate-in');
    }, 0.65)
    .to('.hero-content__image img', {
        y: 0,
        duration: 1.5,
        delay: -0.5,
        ease: 'power2.out',
        opacity: 1,
    })

    var video = $('.video-sizer');
    gsap.to(video, {
        scrollTrigger: {
            trigger: video[0],
            start: 'top 125%',
        },
        maxHeight: "100%",
        duration: 0.6,
        onComplete: function() {
            $('.video-sizer').addClass('animate');
        }
    })

    $('.img-wrapper').each(function() {
        var _this = $(this);
        var imageHeight = $(this).find('img').height();
        if ( $(window).width() <= 768 ) imageHeight = 'none';
        gsap.to(_this, {
            scrollTrigger: {
                trigger: _this,
                start: 'top 150%'
            },
            maxHeight: imageHeight,
            duration: 0.6,
            ease: 'power1.out',
            onComplete: function() {
                _this.addClass('animate');

                let parent = $(this._targets[0]).closest('section');
                if ( parent.find('.content h3').length ) {
                    gsap.to(parent.find('.content h3'), {
                        opacity: 1,
                        duration: 0.8,
                        delay: 0.6,
                        y: 0,
                        ease: 'power1.out',
                    })
                }
            }
        })

        $(window).on('resize', function() {
            var imageHeight =  $(window).width() <= 768 ? imageHeight = 'none' : _this.find('img').height();
            _this.css('max-height', imageHeight);
        })
    })

    

    var basicAnimatedElements = ['.appointment-guide .col-12', '.emailSignUp'];
    for( let i = 0; i < basicAnimatedElements.length; i++ ) {
        gsap.to(basicAnimatedElements[i], {
            opacity: 1,
            y: 0,
            duration: 0.8,
            ease: 'power1.out',
            scrollTrigger: {
                trigger: basicAnimatedElements[i],
                start: 'top 90%',
            }
        })        
    }

    $('.articleSection').each(function() {
        let cards = $(this).find('.articleCard');
        gsap.to(cards, {
            opacity: 1,
            y: 0,
            scrollTrigger: {
                trigger: $(this)[0],
                start: 'top 70%',
            },
            stagger: 0.2,
            duration: 1,
            ease: 'power1.out',
        })
    })
    gsap.to('.shareTitle', {
        y: 0,
        opacity: 1,
        duration: 0.4,
        ease: 'power1.out',
        scrollTrigger: {
            trigger: '.share-callout',
            start: 'top 80%',
        },
    })
    gsap.to('.shareIcon img', {
        stagger: 0.15,
        duration: 0.4,
        ease: 'power1.out',
        delay: 0.2,
        opacity: 1, 
        scrollTrigger: {
            trigger: '.share-callout',
            start: 'top 80%',
        },
    })
}


window.trackingFindADermClick = function () {
    gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': 'DC-9325724/unile0/unile00h+standard'
    });
    console.log("Track FindADermClick")
}

window.trackSpecificSearchTerm = function(term) {
    gtag("event", "search", {
        search_term: term.trim(),
        send_to: "G-12WB6KWQ54"
    });
}

window.trackingSearchStart = function () {
    gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': 'DC-9325724/unile0/unile00i+standard'
    });
    console.log("Track Search Start")
}

window.trackingSearchResultComplete = function () {
    gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': 'DC-9325724/unile0/unile00j+standard'
    });
    console.log("Track Search Results Complete")
}

window.trackingVideoStart = function () {
    gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': 'DC-9325724/unile0/unile00k+standard'
    });
    console.log("Track Video Start")
}

window.trackingGetGuide = function () {
    gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': 'DC-9325724/unile0/unile00l+standard'
    });
    console.log("Track Get Guide")
}

function animateLinks() {
    $('body').on('mouseenter', '.underlined-link', function() {
        $(this).addClass('animate-in');
    })
    $('body').on('mouseleave', '.underlined-link', function() {
        $(this).addClass('begin-animate-out');
        $(this).removeClass('animate-in');
        setTimeout(() => {
            $(this).removeClass('begin-animate-out');
        }, 500);
    })
}
